<template>
  <div>
    <!-- ======= Footer ======= -->
  <hr style="color:#149f6e">
  <footer id="footer">
    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong><span><img src="../assets/img/logo.png" alt="" width="200"></span></strong>
      </div>
      <div class="credits">
        Desarrollado por Parquesoft
      </div>
    </div>
  </footer><!-- End Footer -->
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>
